import React from 'react';
import {
  Added,
  Improved,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="January 2022"
      subnav="release-notes">
      <div id="January2022s" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          Happy New Year! While we get closer and closer to the release of the
          Module components for the Unified Product experience, this
          month's release notes are short and sweet. We spent some time on
          improvements and updates to our libraries and internal processes. More
          exciting releases are coming soon 😏
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.12.9"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Improved>
                Uniform development experience by pinning{' '}
                <code>devDependencies</code> to specific versions.
              </Improved>
            </div>
          </div>
        </div>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Storybook"
              version="iew"
              versionUrl="https://uniform-storybook.hudltools.com"
            />
            <div className={styles.changesList}>
              <Added>
                Dark and light mode support for Storybook UI and stories that
                respects system settings.
              </Added>
              <Improved>Upgraded to the latest version of Storybook.</Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
